.my-component {
  text-align: center;
}

.intro-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 25px;
  justify-content: center;
  gap: 35px;
}

.intro-div div {
  max-width: 500px;
}

.intro-div h2 {
  background-color: black;
  padding: 15px;
  border-radius: 15px;
  color: #ffd900;
  font-weight: 1250;
  font-size: 40px;
}

.intro-div p {
  font-size: 22px;
  margin: 35px 0;
}

.intro-div-img {
  text-align: center;
}

.intro-div-img img {
  max-height: 450px;
}

@media screen and (min-width: 823px) {
  .intro-div {
    height: 87vh;
  }
}

.intro-div-link {
  text-align: center;
}

.intro-div-link a {
  background-color: #ffd900;
  font-size: 25px;
  padding: 10px 35px;
  border-radius: 10px;
  color: black;
  font-weight: 1000;
}

.black-background {
  display: block;
  background-color: black;
  padding: 45px 0;
}

.our-mission,
.why-love-us {
  max-width: 650px;
  margin: 50px auto;
  background-color: #ffd900;
  padding: 20px;
  font-size: 18px;
}

.our-mission h3,
.why-love-us h3 {
  font-size: 24px;
  margin: 10px;
  text-align: center;
}

.our-mission p,
.why-love-us p {
  text-align: start;
}

.our-mission {
  border-radius: 20px 20px 0 0;
}

.why-love-us {
  border-radius: 0 0 20px 20px;
}
