.component_title {
  margin: 60px 0 30px;
  font-size: 30px;
}

.origin-story,
.background {
  max-width: 650px;
  margin: 0 auto;
  font-size: 18px;
  padding: 0 15px;
}

.origin-story h3,
.background h3 {
  margin: 30px 0 5px;
  font-style: italic;
}

.origin-story p,
.background p {
  text-align: start;
  margin-bottom: 10px;
}
