.footer {
  text-align: center;
  font-family: "Courier New", monospace;
  padding: 0 45px;
}

.footer_div {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  align-items: center;
}

.footer_div img {
  height: 100px;
}

.footer_social_div img {
  height: 50px;
  margin-left: 10px;
}
